import React from "react"

import { LANGUAGE } from "actions/constants"

export default {
    [LANGUAGE.EN]: {
        Heading: () => <></>,
        SubHeading: () => (
            <>
                No Photos.
            </>
        ),
        link: "",
    },
    [LANGUAGE.ZH]: {
        Heading: () => <></>,
        SubHeading: () => (
            <>
                沒有相片
            </>
        ),
        link: "",
    },
}
