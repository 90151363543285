import { fetchAuthSession } from 'aws-amplify/auth';
import S3 from "aws-sdk/clients/s3"
import axios from 'axios'


const getS3Client = async (credentials) => {
    // const credentials = await fetchAuthSession()
    return  new S3({
        region: "us-east-1",
        credentials: credentials.credentials,
        // credentials:{
        //     accessKeyId:"ASIAZOA7T44CTQLQN634",
        //     secretAccessKey:"UDk+y9xytFo68yU/lYyqiTCr2Q8l+JlS0zSVji3D",
        //     sessionToken:"IQoJb3JpZ2luX2VjEIj//////////wEaDmFwLXNvdXRoZWFzdC0xIkcwRQIhANonkyLp6M53FCfdh0sF/ZoUzboX7lOcLFWBYFNIJrxjAiB2d73uZNamqkJ4QS3utV2IUdxLiZZ6vq+wNGjCV7vFMSriBAgxEAUaDDY0ODYwNjM3NzczMyIMPipBpb6OjdBDXqurKr8ENvl4lWwJsLscVvmTixgZ891L5hVXbodrQisVb0Hu+kDjxTLam4yVCepMGHKVYy9a9BI0iDOzyCZUy3MlrV16vVhP1uytHoS76EVf1BnwV99EWCHqvQgPUKnBG3PvURPxS9u/V8rU/GI7EBqOyW53VWTWKEC8imcRkfJpQongejCODt1K6shTJXN0a70oHzlQs1ntrRTN3fyFGaB5/KAchQ/NA3+rw95k41p0GDsEtT1rqv2cTVGsrZQrdA1iWxwBU2BdOdf2Zz7XwVj+/gew1nKdfCFp1rwLj/QLXFAppFAbsX/tggLRohYglD9U5aFZfJnTr3aa2CUYEUkFHqmK95lUX4Kb8eTT8hBBuWQ1K4X1PM/icfUTFxNNFtALhp1HrGWPsIhvoK868ylHjFCIyyJVf39a43ITnoW6gPWN8Bx025oImy7Gi8B+th71t+685Qo5EMe/W8/vkU7bBXc6rjFJkDOaXnGDrxmW4ao6bcfWQ5Vff5XlFsEnSzFmZ9CD9XUKnUwMhlcbVYpFzznI78UKOWjsb62zemoBb3QSELaxE0k9TPUm/wqi7Mx1iw69wdWIibeUsCthOP86Ay0vebc1pR2cmPFehYWdsCyPlnKe9D0dBoAFQ7RghEMqNW8rjSVLWxvFiWGTrZzDSKi4dNGKgr9OXooGuaEx9/8ofHyvGTrBMpYwtUMlJ1+6a472PZE8m0d3UwGq1T/I7sJSU9RPhEhJaKXJPpRoBRZ23ygxmHsVU2uQxL4hplXSRU0wjdKXugY6hQILA3KP5MZV81SXKf8L4g12Olp5Kukdct4ft7n3JH84QW1faSJdEmvhQqmjWrWriF99lMd8k5nzeQAz8S+TMfwzpM7/v1Ho2BY0m3wnxTk80Y5JKfBtFiCV4YXXZ25ossnNTuFaf/AWOvKaP85mBAb3DeNvSi3Hs7D9VtF7EvCLuIN9orr35zkTj9hq8RBffoLM+5EhI3cOjK1X+7uiTu+5CGwc1XrJua4eVl7E1jl0v/M6oS12s6ZbjWeMtXBjHbWPBT33fGwtEdIn1pDkxMQ+7RAiOOG8ec0mA1zRcLndzGkK3UwlCCxIIH3mRsccnZhewU3nRysAqTPTLmDvEhUgPnf+2/k="
        // }
    })
}


export const uploadPhotosToS3 = async (credentials,uuid,body,filename,contentType) => {
    const params = {
        Bucket: 'roybucketforstorage',
        Key: `wedding_photos/${uuid}/${filename}`,
        Body: body
      };

     try{
      console.log(credentials)
      const client = await getS3Client(credentials)
      const resfromuploaddirectly = await client.upload(params).promise()
      return resfromuploaddirectly
     }
      catch(err){
          console.log(err)
          return err
      }
    
    // const presignedurl = client.getSignedUrl("putObject",params);
    // console.log(presignedurl)
    // try {
    //     const config = {
    //       method: 'put',
    //       url: presignedurl,
    //       data: body,
    //       headers:{
    //         "Content-Type": contentType
    //       }
    //     }
  
    //    // Upload file to presigned url
    //     console.log(config)
    //     const response = await axios.request(config)  
    //     console.log(response)
    //     return response
    //   } catch (error) {
    //     console.error('Error uploading file:', error)
    //     return error
    //   }
    return 
}
