import React, { useContext, useEffect,useState } from "react"
import { useCookies } from "react-cookie"
import { NavLink as RouterNavLink, withRouter } from "react-router-dom"
import Navbar from "react-bootstrap/Navbar"
import NavLink from "react-bootstrap/NavLink"
import Nav from "react-bootstrap/Nav"
import NavItem from "react-bootstrap/NavItem"
import Dropdown from "react-bootstrap/Dropdown"
import ReactCountryFlag from "react-country-flag"
import classNames from "classnames"

import { selectLanguage } from "utilities/cookies"
import { LANGUAGE } from "actions/constants"
import { signOut } from "actions"
import { Store } from "store"
import { navigation } from "content"
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {APP} from "../../actions/constants"


import styles from "./Navigation.module.scss"

function Navigation({ fromphotopage }) {
    const [cookies, setCookie] = useCookies(["language"])
    const { state, dispatch } = useContext(Store)

    const {
        app: {
            user: { name, isAuthenticated, email },
        },
    } = state

    const handleSelectLanguage = lang =>
        setCookie("language", lang, { path: "/" })

    const handleSignOut = () => signOut(dispatch)

    const languageCodes = {
        [LANGUAGE.ZH]: "ZH",
        [LANGUAGE.EN]: "US",
    }

    const {
        schedule: ScheduleNavText,
        travel: TravelNavText,
        thingsToDo: ThingsToDoNavText,
        faq: FAQNavText,
        registry: RegistryNavText,
        rsvp: RSVPNavText,
        signOut: SignOutText,
        signIn: SignInText,
        manageRsvp: ManageRSVPText,
        TR
    } = navigation[selectLanguage(cookies)]
    // useEffect is used to perform side effects in functional components.
    // Here, it's used to register scroll events and update scrollSpy when the component mounts.
    useEffect(() => {

        // Registering the 'begin' event and logging it to the console when triggered.
        Events.scrollEvent.register('begin', (to, element) => {
            console.log('begin', to, element);
        });

        // Registering the 'end' event and logging it to the console when triggered.
        Events.scrollEvent.register('end', (to, element) => {
            console.log('end', to, element);
        });

        // Updating scrollSpy when the component mounts.
        scrollSpy.update();

        // Returning a cleanup function to remove the registered events when the component unmounts.
        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    // Defining functions to perform different types of scrolling.
    const scrollToTop = () => {
        if (fromphotopage){
            console.log(window.location.href)
            // redirect the page to the host
            window.location.href = "/"
        }else{
            scroll.scrollToTop({
                duration: APP.Constants.SCROLL_DURATION,
                smooth: true
            });
        }
        
    };

    const scrollToBottom = () => {
        scroll.scrollToBottom();
    };

    const scrollTo = () => {
        scroll.scrollTo(100); // Scrolling to 100px from the top of the page.
    };

    const scrollMore = () => {
        scroll.scrollMore(100); // Scrolling an additional 100px from the current scroll position.
    };

    // Function to handle the activation of a link.
    const handleSetActive = (to) => {
        console.log(to);
    };

    const [expanded, setExpanded] = useState(false);
    return (
        <Navbar
            variant="dark"
            expand="lg"
            sticky="top"
            className={styles.navigation}
            expanded={expanded}
            id="header"
        >
            {/* <RouterNavLink
                to="/"
                className={classNames("navbar-brand", styles.brand)}
            >
                T & R
            </RouterNavLink> */}
            <Link activeClass="active" onClick={scrollToTop} className={classNames("navbar-brand", styles.brand)} to="top" spy={true} smooth={true} duration={APP.Constants.SCROLL_DURATION} >{TR}</Link>
            <Navbar.Toggle onClick={() => setExpanded(!expanded)} className={classNames(styles.toggle)} aria-controls="navbarScroll"/>
            <Navbar.Collapse>
                <Nav>
                    {/* <RouterNavLink
                        to="/schedule"
                        className={classNames("nav-link", styles.navlink)}
                        role="button"
                    >
                        <ScheduleNavText />
                    </RouterNavLink> */}
                    <Link onClick={() => (fromphotopage? window.location.href = "/" : setExpanded(false))} activeClass="active" className={classNames("nav-link", styles.navlink)} offset={-300} to="schedulepage" spy={true} smooth={true} duration={APP.Constants.SCROLL_DURATION} >< ScheduleNavText /></Link>
                    {/* <RouterNavLink
                        to="/travel"
                        className={classNames("nav-link", styles.navlink)}
                        role="button"
                    >
                        <TravelNavText />
                    </RouterNavLink> */}
                    <Link onClick={() => (fromphotopage? window.location.href = "/" : setExpanded(false))} activeClass="active" className={classNames("nav-link", styles.navlink)} offset={-300} to="travelpage" spy={true} smooth={true} duration={APP.Constants.SCROLL_DURATION} ><TravelNavText /></Link>
                    {/* <RouterNavLink
                        to="/things-to-do"
                        className={classNames("nav-link", styles.navlink)}
                        role="button"
                    >
                        <ThingsToDoNavText />
                    </RouterNavLink> */}
                    <Link onClick={() => (fromphotopage? window.location.href = "/" : setExpanded(false))} activeClass="active" className={classNames("nav-link", styles.navlink)} offset={-300} to="thingstodopage" spy={true} smooth={true} duration={APP.Constants.SCROLL_DURATION} ><ThingsToDoNavText /></Link>
                    {/* <RouterNavLink to="/faq" className={classNames("nav-link", styles.navlink)} role="button">
                        <FAQNavText />
                    </RouterNavLink> */}
                    {/* <RouterNavLink
                        to="/registry"
                        className={classNames("nav-link", styles.navlink)}
                        role="button"
                    >
                        <RegistryNavText />
                    </RouterNavLink> */}
                    {/* <RouterNavLink
                        to="/rsvp"
                        className={classNames("nav-link", styles.navlink)}
                        role="button"
                    >
                        <RSVPNavText />
                    </RouterNavLink> */}
                    <Link onClick={() => (fromphotopage? window.location.href = "/" : setExpanded(false))} activeClass="active" className={classNames("nav-link", styles.navlink)} offset={-300}  to="rsvppage" spy={true} smooth={true} duration={APP.Constants.SCROLL_DURATION} ><RSVPNavText /></Link>
                </Nav>
                <Nav className="ml-auto">
                    {/* {isAuthenticated && (<div as={NavLink} className={classNames("nav-link", styles.navlink)}>{name}</div>)} */}
                    {isAuthenticated && (
                        
                        <Dropdown as={NavItem} >
                            <Dropdown.Toggle as={NavLink} className={classNames("nav-link", styles.navlink)}>
                                {name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu alignRight>
                                {/* <Dropdown.Item className={styles.email}>
                                    <strong>{name}</strong>
                                </Dropdown.Item> */}
                                <Dropdown.Item onSelect={handleSignOut}>
                                    <SignOutText />
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                    onSelect={() => history.push("/rsvp")}
                                >
                                    <ManageRSVPText />
                                </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                    //  : (
                    //     <RouterNavLink
                    //         to="/auth"
                    //         role="button"
                    //         className={classNames("nav-link", styles.navlink)}
                    //     >
                    //         <SignInText />
                    //     </RouterNavLink>
                    // )
                    }
                    {selectLanguage(cookies) == LANGUAGE.EN ? (
                        <div className={classNames("nav-link", styles.navlink)} onClick={() => {setExpanded(false) ; handleSelectLanguage(LANGUAGE.ZH)}}>
                        <ReactCountryFlag countryCode="HK" svg />{" "}
                        {LANGUAGE.ZH}
                        </div>
                    ) : (
                        <div className={classNames("nav-link", styles.navlink)} onClick={() => {setExpanded(false) ; handleSelectLanguage(LANGUAGE.EN)}}>
                        <ReactCountryFlag countryCode="HK" svg />{" "}
                        {LANGUAGE.EN}
                        </div>
                    )}
                    {/* <Dropdown as={NavItem}>
                        <Dropdown.Toggle as={NavLink} className={styles.navlink}>
                            <ReactCountryFlag
                                countryCode="HK"
                                svg
                            />{" "}
                            {selectLanguage(cookies)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight>
                            <Dropdown.Item
                                eventKey={LANGUAGE.EN}
                                onSelect={handleSelectLanguage}
                                onClick={() => setExpanded(false)}
                            >
                                <ReactCountryFlag countryCode="HK" svg />{" "}
                                {LANGUAGE.EN}
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={LANGUAGE.ZH}
                                onSelect={handleSelectLanguage}
                                onClick={() => setExpanded(false)}
                            >
                                <ReactCountryFlag countryCode="HK" svg />{" "}
                                {LANGUAGE.ZH}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default withRouter(Navigation)
