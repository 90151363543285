import { signUp, signIn, signOut, fetchUserAttributes, fetchAuthSession,getCurrentUser } from "aws-amplify/auth"
import { APP } from "../actions/constants"
import { getUserItemFromDynamo } from "./dynamo"
import CNTIdentity from "aws-sdk/clients/cognitoidentity"
import STS from "aws-sdk/clients/sts"
import AWS from "aws-sdk"

export const signUpWithCognito = params => {
    return new Promise((resolve, reject) => {
        signUp(params)
            .then(data => {
                console.log(data)
                resolve(data)
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

// Auth.signUp(params)

export const signInWithCognito = (username, password) =>
// new Promise((resolve, reject) => {
//     resolve()
// })    
{
    return signIn({
        username,
        password,
    })
}


export const signOutWithCognito = async (dispatch) =>{
    signOut()
    dispatch({
        type: APP.SET.USER_SIGN_OUT,
    })
}
    // new Promise((resolve, reject) => {
    //     resolve()
    // })
    

export const isSignedIn = async() => {
    try{
        await getCurrentUser()
        return true
    }
    catch(e){
        return false
    }
    
}

export const currentAuthenticatedUserWithCognito = async (dispatch) => {
    // get user and credentials

    let CognitoUser

    try {
        CognitoUser = await fetchUserAttributes()
        const
            { name, email, "custom:companion": companion }
                = CognitoUser
        console.log(CognitoUser)
        const credentials = await fetchAuthSession({forceRefresh: true})
        console.log(credentials)
        // const bbb = new CNTIdentity({
        //     credentials:credentials.credentials,
        //     region: "ap-southeast-1",
        // })
        // let Logins = {};
        // Logins["cognito-idp.ap-southeast-1.amazonaws.com/ap-southeast-1_XSTdtBWvr"] = credentials.tokens.idToken.toString();
        // const getCredetailres = await bbb.getCredentialsForIdentity({
        //     IdentityId: credentials.identityId,
        //     Logins: Logins
            
        // }).promise()
        // console.log(getCredetailres)
        // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        //     IdentityPoolId: "ap-southeast-1:ba02a2f7-7b05-4419-a99b-a93a2557f4a2",
        //     Logins:Logins
        // })
        // const sts = new STS({
        //     credentials:credentials.credentials,
        //     region: "ap-southeast-1"
        // })

        // const assumedRole = await sts.assumeRoleWithWebIdentity({
        //     WebIdentityToken: credentials.tokens.idToken.toString(),
        //     RoleArn: "arn:aws:iam::648606377733:role/photousers_role",
        //     RoleSessionName: "photousers"
        // }).promise()
        // console.log(assumedRole)
        // const {Item} = await getUserItemFromDynamo(credentials)
       const userDetail = new User(name,email,credentials,companion)
       const isPhotoUser = credentials.tokens.idToken.payload["cognito:groups"] && credentials.tokens.idToken.payload["cognito:groups"].includes("photousers_group")
        dispatch({
            type: APP.SET.USER_CURRENT,
            payload: {
                username: name,
                name,
                email,
                credentials,
                userDetail,
                isPhotoUser,
            },
        })
    } catch (e) {
        // sign out will clear all existing cognito keys from localStorage
        console.log(e)
        await signOutWithCognito(dispatch)
        CognitoUser = null
    }

    return CognitoUser
}

export class User {
    constructor(name,email,credentials,companion) {
        this.name = name
        this.email = email
        this.username = name
        this.credentials = credentials
        this.companion = []
        console.log(companion,"#!#!@")
        if (companion) {
            companion.split(",").map((_companion, index) => {
                let _c = _companion.trim();
                if (_c != "" ) {
                    this.companion.push(_c)
                }
            })
        }
    }
}