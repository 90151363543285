import React from "react"

import { Header } from "./Header"
import { Details } from "./Details"
import styles from "./Home.module.scss"
import { ThingsToDo } from "../ThingsToDo"
import { Travel } from "../Travel"
import { RSVP } from "../RSVP"
import { Schedule } from "../Schedule"
import { Element } from 'react-scroll'
import { Navigation } from "../../components/Navigation"
function Home() {
    const refContainer = React.useRef()
    const [cDimensions, setCDimensions] = React.useState({
        height: 0,
    });

    const refContainer2 = React.useRef()
    const [dimensions, setDimensions] = React.useState({
        height: 0,
    });
    React.useEffect(() => {
        function handleResize() {
            // only resize when refContainer is different size
            if (cDimensions.height !== refContainer.current.offsetHeight) {
                setCDimensions({
                    height: refContainer.current.offsetHeight,
                })
                // console.log("resize")
                // console.log("window height:", window.innerHeight)
                // console.log("window width:", window.innerWidth)
                // console.log("carousell height:", refContainer.current.offsetHeight)
                // console.log("letter height:", refContainer2.current.offsetHeight)
                // console.log("Header height:", document.getElementById("header").offsetHeight)
                const newLetterH = window.innerHeight - refContainer.current.offsetHeight - document.getElementById("header").offsetHeight;
                console.log(newLetterH, "newLetterH")
                console.log(window.innerHeight / 3, "window.innerHeight / 3")
                // only resize when new height is greater 1/4 of the window height
                if (newLetterH > window.innerHeight / 4) {
                    console.log("set new height")
                    setDimensions({
                        height: window.innerHeight - refContainer.current.offsetHeight - document.getElementById("header").offsetHeight,
                    })
                }

            }
            // console.log("resize")
            // console.log("window height:",window.innerHeight)
            // console.log("window width:",window.innerWidth)
            // console.log("carousell height:",refContainer.current.offsetHeight)
            // console.log("letter height:",refContainer2.current.offsetHeight)
            // console.log("Header height:",document.getElementById("header").offsetHeight)
            // setDimensions({
            //     height: window.innerHeight - refContainer.current.offsetHeight - document.getElementById("header").offsetHeight,
            // })
        }

        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    // trigger the event listener when page loads
    function carousellLoadfunc() {
        console.log("onload")
        // trigger the event listener when page loads
        window.dispatchEvent(new Event('resize'));
    }

    return (
        <><Navigation /><div className={styles.main_container}><div className={styles.container}>
            <div className="dynamicfirstpageheader" ref={refContainer} onLoad={carousellLoadfunc}><Header /></div>
            <div className="dynamicfirstpagedetail" ref={refContainer2} style={{ "min-height": dimensions.height + "px" }}><Details className={styles.detailSize} /></div>
        </div>
            <Element name="schedulepage"><Schedule /></Element>
            <Element name="thingstodopage"><ThingsToDo /></Element>
            <Element name="travelpage"><Travel /></Element>
            <Element name="rsvppage"><RSVP /></Element>
        </div></>
    )
}

export default Home
