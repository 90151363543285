import React, { useState, useEffect, useCallback } from "react"
import { useCookies } from "react-cookie"
import Container from "react-bootstrap/Container"

import { selectLanguage } from "utilities/cookies"
import { viewPhotos } from "content/ViewPhotos"
import { Header } from "components/Header"
import { getPhotosFromS3 } from "actions"

import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import "yet-another-react-lightbox/styles.css";

import { getPhotosFromDynamo } from "services"
import { set } from "lodash"
import { APP } from "../../actions/constants"


function ViewPhotos() {
    const [cookies] = useCookies(["language"])
    const queryParameters = new URLSearchParams(window.location.search)
    const useruuid = queryParameters.get("uuid")
    const { Heading, SubHeading } = viewPhotos[selectLanguage(cookies)]
    const [index, setIndex] = React.useState(-1);
    const [photos, setPhotos] = useState([]);
    if (useruuid) {

    }
    useEffect(() => {

        useruuid && getPhotosFromDynamo(useruuid).then(res => {
            setPhotos(res.Items.map(item => ({
                src: `${APP.Constants.CLOUDFRONT_URI}/${useruuid}/${item.image_name}`,
                width: 3000,
                height: 3000,
                download: {
                    filename: item.image_name
                }
            })))
        })
    }, []);


    // let photos = [
    //     {
    //         src: "https://d2kqzriflwkgxc.cloudfront.net/testuuid/test.jpeg",
    //         width:3000,
    //         height:3000,
    //         download: { 
    //             filename: "aaaaaaa.jpeg"
    //         }
    //     },
    //     {
    //         src: "https://d2kqzriflwkgxc.cloudfront.net/testuuid/1.png",
    //         width:3000,
    //         height:3000,
    //         download: { 
    //             filename: "xyxcaxzc.png"
    //         }
    //     }
    // ];
    return (
        <>
            {photos.length == 0 &&
                <Header
                    // imageUrl={headerImg}
                    Heading={Heading}
                    SubHeading={SubHeading}
                />
            }
            {photos.length > 0 &&
                <Container fluid>
                    <RowsPhotoAlbum
                        photos={photos}
                        targetRowHeight={150}
                        onClick={({ index: current }) => setIndex(current)}
                    />

                    <Lightbox
                        index={index}
                        slides={photos}
                        open={index >= 0}
                        close={() => setIndex(-1)}
                        plugins={[Download]}
                    />
                </Container>
            }

        </>
    )
}

export default ViewPhotos
