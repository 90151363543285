import React, { useState, useEffect, useContext, useRef } from "react"
import { useCookies } from "react-cookie"
import Container from "react-bootstrap/Container"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { selectLanguage } from "utilities/cookies"
import { takePhotos } from "content/TakePhotos"
import { Header } from "components/Header"

import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import "yet-another-react-lightbox/styles.css";

import { getPhotosFromDynamo, uploadPhotosToS3 } from "services"
import { APP } from "../../actions/constants"
import { get, set, Store } from "store"
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import "./takephoto.css"
import { Button } from 'primereact/button';
import QRCode from "react-qr-code";
import { v1 as uuidv1, v4 as uuidv4 } from 'uuid';
import { Navigation } from "components/Navigation";
import Share from "yet-another-react-lightbox/plugins/share";
import imageCompression from 'browser-image-compression';
import pLimit from 'p-limit';
const limit = pLimit(3);




function TakePhotos() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef(null);
    const { state } = useContext(Store)
    const {
        app: {
            user: { username, credentials, userDetail, isAuthenticated, isPhotoUser },
        },
    } = state
    const [cookies] = useCookies(["language"])
    const queryParameters = new URLSearchParams(window.location.search)
    const [useruuid, setuseruuid] = useState("");
    const { Heading, SubHeading } = takePhotos[selectLanguage(cookies)]
    const [index, setIndex] = React.useState(-1);
    const [photos, setPhotos] = useState([]);

    const sectionRef = useRef(null);
    const opacityRef = useRef(null);

    const openLoading = () => {
        if (!opacityRef.current.classList.contains("modal-open")) {
            opacityRef.current.classList.add("modal-open");
        }
        // sectionRef.current.style.overflow = "hidden";
    }

    const closeLoading = () => {
        if (opacityRef.current.classList.contains("modal-open")) {
            opacityRef.current.classList.remove("modal-open");
        }
        // sectionRef.current.style.overflow = "auto"
    }
    useEffect(() => {
        (async () => {
            openLoading();
            const uuidfromurl = queryParameters.get("uuid")
            console.log(uuidfromurl)
            setuseruuid(uuidfromurl)
            if (uuidfromurl && uuidfromurl != "") {
                const getfromddbres = await getPhotosFromDynamo(uuidfromurl)
                console.log(getfromddbres)
                // get the base64 of the image
                const slides = [];
                for (let i = 0; i < getfromddbres.Items.length; i++) {
                    const item = getfromddbres.Items[i];
                    const url = `${APP.Constants.CLOUDFRONT_URI}/${uuidfromurl}/${item.image_name}`
                    // const response = await fetch(url);
                    // const blob = await response.blob();
                    // let b64img = await blobToBase64(blob)
                    slides.push({
                        url: url,
                        src: url,
                        // blob: blob,
                        width: 3000,
                        height: 3000,
                        download: {
                            src: `${APP.Constants.CLOUDFRONT_URI}/${uuidfromurl}/${item.image_name}?download`,
                            filename: item.image_name
                        },
                        share: {
                            title: item.image_name,
                            url: `${APP.Constants.CLOUDFRONT_URI}/${uuidfromurl}/${item.image_name}`,
                        },
                        imageFit: "contain"
                    })
                }
                setPhotos(slides)
            }
            closeLoading();
        })();
    }, []);



    const getFileExt = (filetype) => {
        return filetype.split("/")[1]
    }
    const getFileName = (ext) => {
        return `${username}_${Date.now()}_${uuidv4().split("-")[0]}.${ext}`
    }
    const compressOptions = {
        maxSizeMB: 1,
        useWebWorker: true
      }
    const compressAndUpload = async (file) => {
        const compressedFile = await imageCompression(file, compressOptions);
        const ext = getFileExt(compressedFile.type)
        const filename = getFileName(ext)
        console.log(filename)
        console.log(compressedFile.size / 1024 / 1024)
        console.log(file.size / 1024 / 1024)
        return uploadPhotosToS3(credentials, useruuid, compressedFile, filename, compressedFile.type)
    }
    const handleUploadPhoto = async (e) => {
        // Do stuff with the photo...
        console.log('uploading photos');
        console.log(e.files)
        console.log(username)
        // console.log(toast.current.show("Uploaded Successfully."))
        openLoading()
        const responses = await Promise.all(e.files.map(file => {
            return limit(() => compressAndUpload(file))
        }));

        console.log(responses)
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
        closeLoading()

    }

    const load = () => {
        setLoading(true);
        const newuuid = uuidv1()
        setuseruuid(newuuid)
        // update uuid parameter in url and keep the rest
        const url = new URL(window.location.href);
        url.searchParams.set('uuid', newuuid);
        window.history.pushState({}, '', url);
        // reload the pages
        window.location.reload();
        setLoading(false);
    };

    const CustomShare = async ({ slide }) => {
        console.log(slide)
        if ('canShare' in navigator) {
            try {
                // const response = await fetch(slide.url);
                // const blob = await response.blob();
                // const file = new File([blob], slide.share.title, { type: blob.type });
                const response = await fetch(slide.url);
                const blob = await response.blob();
                const file = new File([blob], slide.share.title, { type: blob.type });
                await navigator.share({
                    url: slide.url,
                    title: slide.share.title,
                    files: [file]
                });
            } catch (err) {
                console.log(err.name, err.message);
            }
        }

    }

    return (
        <><Navigation fromphotopage={true} /><>
            <section ref={sectionRef}>
                <div className="modal" ref={opacityRef}><div className="loader"></div></div>
                <Toast ref={toast}></Toast>
                <Container fluid>
                    <br />
                    {/* {isAuthenticated && isPhotoUser && (!useruuid || useruuid == "") && */}
                    {isAuthenticated && isPhotoUser &&
                        <Row style={{ textAlign: "center" }}>
                            <Col>
                                <Button label="Generate new uuid" icon="pi pi-check" loading={loading} onClick={load} severity="info" />
                            </Col>
                        </Row>}
                    {isAuthenticated && isPhotoUser && useruuid && useruuid != "" &&
                        <><Row>
                            <Col>
                                <QrCodeGenerator value={`https://rsvp.backings.life/viewphotos?uuid=${useruuid}`} />
                            </Col>
                        </Row>
                            <br />
                            <Row>
                                <Col>
                                    <FileUpload ref={fileUploadRef} name="myphotos[]" customUpload uploadHandler={handleUploadPhoto} multiple accept="image/*" maxFileSize={0} auto emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                                </Col>
                            </Row>
                            <br />
                        </>}
                    {photos.length == 0 &&
                        <><Row>
                            <Col>
                                <p style={{ textAlign: "center" }}>{SubHeading()}</p>
                            </Col>
                        </Row><br /></>}
                    <Row>
                        <Col>
                            <RowsPhotoAlbum
                                photos={photos}
                                targetRowHeight={150}
                                onClick={({ index: current }) => setIndex(current)} />
                            <Lightbox
                                index={index}
                                slides={photos}
                                open={index >= 0}
                                close={() => setIndex(-1)}
                                plugins={[Download, Share]}
                                share={{
                                    share: CustomShare
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </></>
    )
}

function QrCodeGenerator({ value }) {
    return (
        <div style={{ height: "auto", margin: "0 auto", width: "100%", textAlign: "center" }}>
            <h4 style={{ textAlign: "center" }}>Your QR Code</h4>
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "30%" }}
                value={value}
                viewBox={`0 0 256 256`}
            />
        </div>
    );
}

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}



export default TakePhotos
